import React, { useState, useEffect } from 'react';
import './styles.css';
import RouteLayout from '../layouts/RouteLayout';
import {IconTools} from "@tabler/icons-react";
import { useParams } from 'react-router-dom';
import useModel from '../hooks/useModel';
import * as XLSX from 'xlsx';
import axios from 'axios';


const FinalReport = () => {

  const { reportId } = useParams();
  const [model, setModel] = useState([]); // Estado para los modelos filtrados

  useEffect(() => {
    // Hacer una petición al backend para obtener los tipos de equipos médicos asociados al reporte
    axios.get(`http://127.0.0.1:8000/api/reports/${reportId}/`)
      .then(response => {
        const report = response.data;
        const selectedTypes = report.types; // Los tipos de equipos médicos asociados al reporte
        console.log('Tipos de equipos medicos cargados para el reporte: ', selectedTypes)
        
        // Obtener los modelos que coinciden con esos tipos
        axios.get('http://127.0.0.1:8000/api/models/')
          .then(res => {
            const allModels = res.data;
            const filteredModels = allModels.filter(model => 
              selectedTypes.includes(model.medical_equipment_id)
            );
            setModel(filteredModels); // Guardar los modelos filtrados
          })
          .catch(error => console.error("Error fetching models: ", error));
      })
      .catch(error => console.error("Error fetching report: ", error));
  }, [reportId]);

  
  console.log('modelos cargados para la tabla: ', model)
const exportToExcel = () => {
  // Obtén los datos de cotización
  const data = model;

  // Crear un mapa para agrupar datos por tipo de equipo médico
  const groupedData = {};

  data.forEach(item => {
      const { equipment_technical_specifications = [] } = item;
      const { model_technical_specifications = [] } = item;

      // Obtener el nombre del proveedor (asumiendo que solo hay un proveedor)
      const supplierName = item.suppliers_name && item.suppliers_name.length > 0 ? item.suppliers_name[0] : "Sin Información";

      // Asegúrate de inicializar la entrada en groupedData
      if (!groupedData[item.medical_equipment_display]) {
          groupedData[item.medical_equipment_display] = {
              specifications: equipment_technical_specifications.map(spec => spec.specification),
              models: []
          };
      }

      groupedData[item.medical_equipment_display].models.push({
          supplier: supplierName,
          brand: item.brand_name || "Sin Información",
          model: item.name || "Sin Información",
          compliance: model_technical_specifications.map(tech => tech.technical_compliance || "Sin Información"),
          manual: model_technical_specifications.map(tech => tech.manual_reference_page || "Sin Información")
      });
  });

  // Preparar los datos para el Excel
  const worksheetData = [];
  Object.entries(groupedData).forEach(([type, { specifications, models }]) => {
      const maxRows = specifications.length;

      for (let i = 0; i < maxRows; i++) {
          const row = {
              'TIPO': i === 0 ? type : '',
              'ESPECIFICACIONES TÉCNICAS MÍNIMAS EXIGIDAS POR LA ESE': specifications[i] || '',
          };

          models.forEach((model, index) => {
              row[`NOMBRE PROVEEDOR ${index + 1}`] = model.supplier || 'Sin Información';
              row[`MARCA ${index + 1}`] = model.brand || 'Sin Información';
              row[`MODELO ${index + 1}`] = model.model || 'Sin Información';
              row[`ESPECIFICACIONES COTIZACION ${index + 1}`] = model.compliance[i] || 'Sin Información';
              row[`FOLIO N° ${index + 1}`] = model.manual[i] || 'Sin Información';
          });

          worksheetData.push(row);
      }
  });

  // Crear una hoja de trabajo de Excel
  const worksheet = XLSX.utils.json_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Cotizaciones');

  // Lógica para combinar celdas
  if (!worksheet['!merges']) worksheet['!merges'] = [];

  let startIndex = 2; // Empieza en la segunda fila (1-based index)
  Object.entries(groupedData).forEach(([type, { specifications, models }]) => {
      const rowCount = specifications.length;

      // Combinar celdas en la columna 'TIPO'
      worksheet['!merges'].push({ s: { r: startIndex - 1, c: 0 }, e: { r: startIndex + rowCount - 2, c: 0 } });

      // Combinar celdas para cada modelo
      models.forEach((model, index) => {
          worksheet['!merges'].push({ s: { r: startIndex - 1, c: 2 + (index * 5) }, e: { r: startIndex + rowCount - 2, c: 2 + (index * 5) } }); // NOMBRE PROVEEDOR
          worksheet['!merges'].push({ s: { r: startIndex - 1, c: 3 + (index * 5) }, e: { r: startIndex + rowCount - 2, c: 3 + (index * 5) } }); // MARCA
          worksheet['!merges'].push({ s: { r: startIndex - 1, c: 4 + (index * 5) }, e: { r: startIndex + rowCount - 2, c: 4 + (index * 5) } }); // MODELO
      });

      startIndex += rowCount;
  });

  // Exportar el archivo Excel
  XLSX.writeFile(workbook, 'cotizaciones.xlsx');
};

return (
  <div>
    <RouteLayout title='Reporte Final de Cotizaciones para Equipos Medicos' icon={<IconTools />}>
      <table className="equipment-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Technical Specifications</th>
            <th>Supplier</th>
            <th>Brand</th>
            <th>Model</th>
            <th>Quotation Specifications</th>
            <th>Manual Reference Page</th>
          </tr>
        </thead>
        <tbody>
          {model.map(item => (
            <tr key={item.id}>
              <td className="type-column">
                {item.medical_equipment_display}
              </td>
              <td className="spec-list">
                <ul>
                  {item.equipment_technical_specifications && item.equipment_technical_specifications.length > 0 ? (
                    item.equipment_technical_specifications
                      .sort((a, b) => a.order - b.order)
                      .map((techSpec, index) => {
                        return(
                      <li key={index}>
                        {techSpec.specification}
                      </li>
                    )})
                  ) : (
                    <li>Sin Información</li>
                  )}
                </ul>
              </td>
              <td className="type-column">
                  {item.suppliers_name || "Sin Información"}
              </td>
              <td className="type-column">
                  {item.brand_name || "Sin Información"}
              </td>
              <td className="type-column">
                  {item.name || "Sin Información"}
              </td>
              <td className="spec-list">
                <ul>
                  {item.model_technical_specifications && item.model_technical_specifications.length > 0 ? (
                      item.model_technical_specifications
                        .map((techCompli, index) => {
                          return(
                        <li key={index}>
                          {techCompli.technical_compliance}
                        </li>
                      )})
                    ) : (
                      <li>Sin Información</li>
                  )}
                </ul>
              </td>
              <td className="spec-list">
                <ul>
                  {item.model_technical_specifications && item.model_technical_specifications.length > 0 ? (
                      item.model_technical_specifications
                        .map((techCompli, index) => {
                          return(
                        <li key={index}>
                          {techCompli.manual_reference_page}
                        </li>
                      )})
                    ) : (
                      <li>Sin Información</li>
                  )}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={exportToExcel}>Exportar a Excel</button>
    </RouteLayout>
  </div>
);
};

export default FinalReport;

