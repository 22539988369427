import { useEffect, useState } from 'react';
import client from "../axiosConfig";

const useMedicalEquipment = () => {
    const [equipment, setEquipment] = useState([]);
    const [subtypes, setSubtypes] = useState([]);


    const reloadEquipment = async () => {
        try {
            const response = await client.get('/api/medical_equipment/');
            setEquipment(response.data);
            console.log('Datos cargados desde reloadEquipment:', response.data);
        } catch (error) {
            console.error('Error al recargar los equipos médicos:', error);
        }
    };

    const createEquipment = async (newEquipment) => {
        console.log('ESTOS SON LOS DATOS desde createEquipment', newEquipment)
        try {
            const response = await client.post('/api/medical_equipment/', newEquipment);
            setEquipment((prevEquipment) => [...prevEquipment, response.data]);
        } catch (error) {
            console.error('Error creating medical equipment:', error);
        }
        };

    const deleteEquipment = async (id) => {
        try {
            await client.delete(`/api/medical_equipment/${id}/`);
            setEquipment((prevEquipment) => prevEquipment.filter((item) => item.id !== id));
        } catch (error) {
            console.error('Failed to delete equipment', error);
        }
        };

    const editEquipment = async (updatedEquipment) => {
        try {
            const response = await client.put(
                `/api/medical_equipment/${updatedEquipment.id}/`,
                updatedEquipment
            );
            setEquipment((prevEquipment) =>
                prevEquipment.map((item) => (item.id === updatedEquipment.id ? response.data : item))
            );
        } catch (error) {
            console.error('Error editing medical equipment:', error);
        }
    };

    const createSubtype = async (newSubtype) => {
        try {
            const response = await client.post('/api/medical_equipment/', newSubtype);
            setSubtypes(prevSubtypes => [...prevSubtypes, response.data]);
        } catch (error) {
            console.error("Error creating subtype:", error);
        }
    };

    const editSubtype = async (updatedSubtype) => {
        try {
            const response = await client.put(`/api/medical_equipment/${updatedSubtype.id}/`, updatedSubtype);
            setSubtypes(prevSubtypes => prevSubtypes.map(subtype => subtype.id === updatedSubtype.id ? response.data : subtype));
        } catch (error) {
            console.error("Error editing subtype:", error);
        }
    };

    const deleteSubtype = async (subtypeId) => {
        try {
            await client.delete(`/api/medical_equipment/${subtypeId}/`);
            setSubtypes(prevSubtypes => prevSubtypes.filter(subtype => subtype.id !== subtypeId));
        } catch (error) {
            console.error("Error deleting subtype:", error);
        }
    };

    useEffect(() => {
        reloadEquipment();
    }, []);

    return {
        equipment,
        subtypes,
        reloadEquipment,
        createEquipment,
        editEquipment,
        deleteEquipment,
        createSubtype,
        editSubtype,
        deleteSubtype,
    };
};

export default useMedicalEquipment;
