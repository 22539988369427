import { IconPencil, IconPlus, IconTrash, IconReportMedical, IconArrowDown, IconArrowUp, IconRotateClockwise } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import './styles.css';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import GeneralModal from '../components/modals/GeneralModal';
import useMedicalEquipment from '../hooks/useMedicalEquipment';
import useSpecification from '../hooks/useSpecification';
import RouteLayout from '../layouts/RouteLayout';
import axios from 'axios';


const HeaderBtn = ({ onClick }) => (
    <div className='h-full flex flex-row items-center pr-4'>
        <button className='add-button' onClick={onClick}><IconReportMedical />Añadir Nuevo Equipo</button>
    </div>
)

function MedicalEquipmentList() {

    const { equipment, createEquipment, deleteEquipment, editEquipment, reloadEquipment, createSubtype, editSubtype, deleteSubtype } = useMedicalEquipment();
    
    const { createSpecification, deleteSpecification, editSpecification } = useSpecification();
    const [showModalDeleted, setShowModalDeleted] = useState(false);
    const [showModalCreatedEquipment, setShowModalCreatedEquipment] = useState(false);
    const [showModalUpdateEquipment, setShowModalUpdateEquipment] = useState(false);
    const [showModalCreatedSubtype, setShowModalCreatedSubtype] = useState(false);
    const [showModalUpdateSubtype, setShowModalUpdateSubtype] = useState(false);

    const [selectedItemId, setSelectedItemId] = useState("");
    const [selectedItemName, setSelectedItemName] = useState("");
    const [selectedItemType, setSelectedItemType] = useState("");
    const [selectedEquipmentId, setSelectedEquipmentId] = useState("");
    const [selectedSubtypeId, setSelectedSubtypeId] = useState("");
    const [equipmentName, setEquipmentName] = useState("");
    const [subtypeName, setSubtypeName] = useState("");
    const [selectedSpecificationId, setSelectedSpecificationId] = useState(null);
    const [specificationName, setSpecificationName] = useState("");

  
    const openDeleteModal = (id, name, type) => {
        setSelectedItemId(id);
        setSelectedItemName(name);
        setSelectedItemType(type);
        setShowModalDeleted(true);
        console.log(`Eliminando el elemento con id: ${id}, nombre: ${name} y tipo: ${type}`);
    };

    const handleDelete = async () => {
        if (selectedItemId !== null) {
            if (selectedItemType === 'equipment') {
                await deleteEquipment(selectedItemId);
                reloadEquipment(); // Recargar los datos después de eliminar un equipo
            } else if (selectedItemType === 'subtype') {
                await deleteSubtype(selectedItemId);
                reloadEquipment(); // Recargar los datos después de eliminar una especificación
            } else if (selectedItemType === 'specification') {
                await deleteSpecification(selectedItemId);
                reloadEquipment(); // Recargar los datos después de eliminar una especificación
            }
        }
        setShowModalDeleted(false);
        setSelectedItemId("");
        setSelectedItemName("");
        setSelectedItemType("");

    };

    const handleCreateEquipement = async () => {
        console.log("Creating equipment:", equipmentName);
        await createEquipment({type: equipmentName});
        setShowModalCreatedEquipment(false);
        setEquipmentName("");
        reloadEquipment();
    };

    const handleCloseCreateEquipement = () => {
        setShowModalCreatedEquipment(false);
        setSelectedEquipmentId("");
        setEquipmentName("");
    }

    const handleUpdateEquipement = async () => {
        console.log("Update equipment:", equipmentName);
        await editEquipment({
            id: selectedEquipmentId,
            type: equipmentName
        });
        setShowModalUpdateEquipment(false);
        setSelectedEquipmentId("");
        setEquipmentName("");
        reloadEquipment();
    };

    const handleCloseUpdateEquipement = () => {
        setShowModalUpdateEquipment(false);
        setSelectedEquipmentId("");
        setEquipmentName("");
    }

    const handleCreateSubtype = async () => {
        console.log("Creating Subtype:", subtypeName, "for equipment ID:", selectedEquipmentId);
        await createSubtype({
            type: subtypeName,
            parent: selectedEquipmentId
        });
        setShowModalCreatedSubtype(false);
        setEquipmentName("");
        setSubtypeName("")
        setSelectedEquipmentId("")
        reloadEquipment()
    };

    const handleCloseCreateSubtype = () => {
        setShowModalCreatedSubtype(false);
        setEquipmentName("");
        setSubtypeName("");
        setSelectedEquipmentId("");
    }

    const handleUpdateSubtype = async () => {
        console.log("Update Subtype:", subtypeName, ", Id Subtype: ", selectedSubtypeId, ", medical_equipment id :", selectedEquipmentId);
        await editSubtype({
            id: selectedSubtypeId,
            type: subtypeName,
            parent: selectedEquipmentId
        });
        setShowModalUpdateSubtype(false);
        setEquipmentName("");
        setSelectedEquipmentId("");
        setSubtypeName("");
        setSelectedSubtypeId("");
        reloadEquipment();
    };

    const handleCloseUpdateSubtype = () => {
        setShowModalUpdateSubtype(false);
        setEquipmentName("");
        setSelectedEquipmentId("");
        setSubtypeName("");
        setSelectedSubtypeId("");
    }

    const handleCreateSpecification = async () => {
        try {
            const selectedEquipment = equipment.find(eq => eq.id === parseInt(selectedSubtypeId));
            const order = selectedEquipment?.technical_specifications.length + 1 || 1;
    
            const response = await createSpecification({
                specification: specificationName,
                medical_equipment: selectedSubtypeId,
                order: order
            });

        } catch (error) {
            console.error("Error submitting specifications:", error);
        }
        setSpecificationName("");
        reloadEquipment();
    };
    
    const handleUpdateSpecification = async () => {
        console.log("Update Specification:", specificationName, ", Id specification: ", selectedSpecificationId, ", medical_equipment id :", selectedEquipmentId);
        await editSpecification({
            id: selectedSpecificationId,
            specification: specificationName,
            medical_equipment: selectedEquipmentId
        });
        setSelectedEquipmentId(null);
        setSpecificationName(null);
        setSelectedSpecificationId(null);
        reloadEquipment();
    };

    const handleMoveSpecificationUp = async (index) => {
        const selectedEquipment = equipment.find(eq => eq.id === parseInt(selectedSubtypeId)) || { technical_specifications: [] };
        const specifications = selectedEquipment.technical_specifications;

        if (index > 0) {
            const currentSpec = specifications[index];
            const previousSpec = specifications[index - 1];
        
            // Intercambiar los valores de orden
            await updateSpecificationOrder(currentSpec.id, previousSpec.order);
            await updateSpecificationOrder(previousSpec.id, currentSpec.order);
        
            reloadEquipment();
        }
    };
      
      const handleMoveSpecificationDown = async (index) => {
        const selectedEquipment = equipment.find(eq => eq.id === parseInt(selectedSubtypeId)) || { technical_specifications: [] };
        const specifications = selectedEquipment.technical_specifications;
      
        if (index < specifications.length - 1) {
          const currentSpec = specifications[index];
          const nextSpec = specifications[index + 1];
      
          // Intercambiar los valores de orden
          await updateSpecificationOrder(currentSpec.id, nextSpec.order);
          await updateSpecificationOrder(nextSpec.id, currentSpec.order);
      
          reloadEquipment();
        }
      };

    const updateSpecificationOrder = async (id, newOrder) => {
        try {
            const response = await axios.put(`http://127.0.0.1:8000/api/update_specification_order/${id}/`, {
                order: newOrder,
            });

            if (response.status === 200) {
                console.log("Order updated successfully");
                reloadEquipment(); // Para recargar los datos y actualizar el estado
            }
        } catch (error) {
            console.error("Error updating specification order:", error);
        }
    };
    

    return (
    <div>
        <RouteLayout title='Lista de Equipos Medicos' icon={<IconReportMedical />} headerItem={<HeaderBtn onClick={() => setShowModalCreatedEquipment(true)} />}>
            <table className="equipment-table">
                <thead>
                <tr>
                    <th>Equipo Medico Padre</th>
                    <th>Subtipo</th>
                </tr>
                </thead>
                <tbody>
                    {equipment
                    .filter(item => item.parent === null)
                    .map(parent => (
                        <tr key={parent.id}>
                        <td className="type-column">
                            <span className="type-text">{parent.type}
                                <div className="type-action-buttons">
                                    <button className="edit-button" onClick={() => {setShowModalUpdateEquipment(true); setSelectedEquipmentId(parent.id); setEquipmentName(parent.type)}} >
                                        <IconPencil />
                                    </button>
                                    <button className="delete-button" onClick={() => openDeleteModal(parent.id, parent.type, 'equipment')}>
                                        <IconTrash />
                                    </button>
                                </div>
                            </span>
                        </td>
                        <td className="spec-list">
                            <ul>
                            {equipment.filter(subtype => subtype.parent === parent.id).length > 0 ? (
                                equipment.filter(subtype => subtype.parent === parent.id).map(subtype => (
                                <li key={subtype.id}>
                                    <span className="type-text">{subtype.type}
                                        <div className="type-action-buttons">
                                            <button onClick={() => {
                                                setShowModalUpdateSubtype(true);
                                                setSelectedSubtypeId(subtype.id);
                                                setSelectedEquipmentId(parent.id);
                                                setEquipmentName(parent.type);
                                                setSubtypeName(subtype.type)
                                                }} className="edit-button" >
                                                <IconPencil />
                                            </button>
                                            <button className="delete-button" onClick={() => openDeleteModal(subtype.id, subtype.type, 'subtype')}>
                                                <IconTrash />
                                            </button>
                                        </div>
                                    </span>
                                </li>
                            ))
                            ) : (
                                <li>Sin Información</li>
                            )}
                                <li className="add-spec-item">
                                    <button onClick={() => {setShowModalCreatedSubtype(true); setSelectedEquipmentId(parent.id); setEquipmentName(parent.type)}}  className="add-button">
                                    <IconPlus /> Crear Subtipo
                                    </button>
                                </li>
                            </ul>
                        </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </RouteLayout>
        <GeneralModal
        isOpen={showModalCreatedEquipment}
            onClose={handleCloseCreateEquipement}
            title="Crear Equipo Medico"
            footerActions={
                <button
                    type="button"
                    onClick={handleCreateEquipement}
                >
                Crear
                </button>}
        >
                <div>
                    <label>Nombre Equipo</label>
                    <input
                        type="text"
                        value={equipmentName}
                        onChange={(e) => setEquipmentName(e.currentTarget.value)}
                    />
                </div>
        </GeneralModal>
        <GeneralModal
        isOpen={showModalUpdateEquipment}
            onClose={handleCloseUpdateEquipement}
            title="Editar Equipo Medico"
            footerActions={
                <button
                    type="button"
                    onClick={handleUpdateEquipement}
                >
                Actualizar
                </button>}
        >
                <div>
                    <label>Nombre Equipo</label>
                    <input
                        type="text"
                        value={equipmentName}
                        onChange={(e) => setEquipmentName(e.currentTarget.value)}
                    />
                </div>
        </GeneralModal>
        <GeneralModal
        isOpen={showModalCreatedSubtype}
            onClose={handleCloseCreateSubtype}
            title="Crear Subtipo"
            footerActions={
                <button
                    type="button"
                    onClick={handleCreateSubtype}
                >
                Crear
                </button>}
        >
            <div>
            <label>Equipo Médico</label>
            <input
                type="text"
                value={equipmentName}
                readOnly
            />
            </div>
            <div>
                <label>Subtipo</label>
                <input
                    type="text"
                    value={subtypeName}
                    onChange={(e) => setSubtypeName(e.currentTarget.value)}
                />
            </div>
        </GeneralModal>
        <GeneralModal
        isOpen={showModalUpdateSubtype}
            onClose={handleCloseUpdateSubtype}
            title="Actualizar Subtipo"
            footerActions={
                <div>
                    <button type="button" onClick={handleUpdateSubtype}>Actualizar</button>
                </div>
            }
        >
            <div>
            <label>Equipo Médico Padre</label>
            <input
                type="text"
                value={equipmentName}
                readOnly
            />
            </div>
            <div>
                <label>Subtipo</label>
                <input
                    type="text"
                    value={subtypeName}
                    onChange={(e) => setSubtypeName(e.currentTarget.value)}
                />
            </div>
            <div>
                    <h5>Especificaciones Técnicas</h5>
                    <table className="equipment-table">
                    <thead>
                        <tr>
                        <th>No.</th>
                        <th>Descripción</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {equipment.find(eq => eq.id === parseInt(selectedSubtypeId))
                    ? (
                    equipment.find(eq => eq.id === parseInt(selectedSubtypeId)).technical_specifications.length > 0
                        ? (
                        equipment.find(eq => eq.id === parseInt(selectedSubtypeId)).technical_specifications
                            .sort((a, b) => a.order - b.order)
                            .map((spec, index) => (
                            <tr key={spec.id}>
                                <td>{index + 1}</td>
                                <td>{spec.specification}</td>
                                <td>
                                    <div className="type-action-buttons">
                                        <button className="edit-button" onClick={() => handleMoveSpecificationUp(index)}><IconArrowUp /></button>
                                        <button className="delete-button" onClick={() => handleMoveSpecificationDown(index)}><IconArrowDown /></button>
                                        <button className="edit-button" onClick={() => handleUpdateSpecification(spec.id)}><IconPencil /></button>
                                        <button className="delete-button"onClick={() => openDeleteModal(spec.id, spec.specification, 'specification')}><IconTrash /></button>
                                    </div>
                                </td>
                            </tr>
                            ))
                        ) 
                        : (
                            <tr>
                            <td colSpan="3">Sin Información</td>
                            </tr>
                        )
                        ) 
                        : (
                        <tr>
                            <td colSpan="3">Sin Información</td>
                        </tr>
                        )}
                    </tbody>
                    </table>
                    <div>
                        <input 
                            type = "text"
                            value = {specificationName}
                            onChange={(e) => setSpecificationName(e.currentTarget.value)}
                            placeholder='Nueva Especificación'
                        />
                         <button onClick={handleCreateSpecification}  className="add-button">
                            <IconPlus /> Añadir Especificación
                        </button>
                    </div>
                </div>
        </GeneralModal>
        <ConfirmationModal
            show={showModalDeleted}
            onClose={() => setShowModalDeleted(false)}
            onConfirm={handleDelete}
            title="Confirmar Eliminación"
            body={`¿Está seguro que desea eliminar el elemento "${selectedItemName}"?`}
        />
    </div>
    );
}

export default MedicalEquipmentList;