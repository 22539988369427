import { useEffect, useState } from 'react';
import client from "../axiosConfig";

const useModel = () => {
    const [model, setModel] = useState([]);

    const reloadModel = async () => {
        try {
            const response = await client.get('/api/models/');
            setModel(response.data);
            console.log('Datos cargados desde reload de Model:', response.data);
        } catch (error) {
            console.error('Error fetching Model:', error);
        }
        };


    const createModel = async (newModel) => {
        console.log('ESTOS SON LOS DATOS', newModel)
        try {
            const response = await client.post('/api/models/', newModel);
            setModel((prevModel) => [...prevModel, response.data]);
        } catch (error) {
            console.error('Error creating Model:', error);
        }
        };

    const deleteModel = async (id) => {
        try {
            await client.delete(`/api/models/${id}/`);
            setModel((prevModel) => prevModel.filter((item) => item.id !== id));
        } catch (error) {
            console.error('Failed to delete Model', error);
        }
        };

    const editModel = async (updatedModel) => {
        try {
            const response = await client.put(
                `/api/models/${updatedModel.id}/`,
                updatedModel
            );
            setModel((prevModel) =>
                prevModel.map((item) => (item.id === updatedModel.id ? response.data : item))
            );
        } catch (error) {
            console.error('Error editing Model:', error);
        }
    };
    

    useEffect(() => {
        reloadModel();
    }, []);

    return {
        model,
        reloadModel,
        createModel,
        deleteModel,
        editModel
    };
};

export default useModel;