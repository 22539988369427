import React from 'react';

const SideBarItem = ({ item, active, onClick }) => {
    return (
        <div
            className={`flex flex-row gap-3 text-gray-500 pb-3 cursor-pointer hover:text-gray-400 ${active ? 'text-white' : ''}`}
            onClick={onClick}
        >
            {item.icon}
            {item.name}
        </div>
    );
};

export default SideBarItem;
