import React, { useState, useEffect } from 'react';
import { IconPencil, IconTrash, IconReportMedical, IconEye, IconRotateClockwise } from '@tabler/icons-react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import RouteLayout from '../layouts/RouteLayout';
import {IconTools} from "@tabler/icons-react";
import useModel from '../hooks/useModel';
import useMedicalEquipment from '../hooks/useMedicalEquipment';
import * as XLSX from 'xlsx';
import axios from 'axios';
import CreateReportModal from '../components/modals/CreateReportModal'; // Importa el modal

const HeaderBtn = ({ onClick }) => (
  <div className='h-full flex flex-row items-center pr-4'>
      <button className='add-button' onClick={onClick}><IconReportMedical />Crear Nuevo Reporte</button>
  </div>
)


const FinalReportU = () => {
  const { model, reload } = useModel();
  const { equipment } = useMedicalEquipment();
  const [showModal, setShowModal] = useState(false);
  const [reports, setReports] = useState([]); // Guarda los reportes creados
  const navigate = useNavigate();


  console.log('Reportes cargados son : ', reports)
  console.log('Equipos Medicos cargados: ', equipment)
  const availableTypes = [...new Set(equipment.filter(eq => eq.parent !== null)
                                              .map(eq => {
                                                const parent = equipment.find(parentEq => parentEq.id === eq.parent);
                                                const parentName = parent ? parent.type : '';
                                                return {
                                                    id: eq.id,
                                                    type: eq.type,
                                                    parentName: parentName
                                                };
                                              })
                          )]; 

  console.log('Equipos que se envian al modal: ', availableTypes)
  useEffect(() => {
    // Obtener los reportes desde la base de datos cuando se carga el componente
    axios.get('http://127.0.0.1:8000/api/reports/')
      .then(response => setReports(response.data))
      .catch(error => console.error('Error fetching reports:', error));
  }, []);

  const handleCreateReport = (reportName, selectedTypes) => {
    // Crear un nuevo reporte y guardarlo en la base de datos

    // const selectedTypeIds = model
    //   .filter(item => selectedTypes.includes(item.medical_equipment_display))
    //   .map(item => item.id);  // Obtener los IDs de los equipos seleccionados

    axios.post('http://127.0.0.1:8000/api/reports/', { name: reportName, types: selectedTypes   })
      .then(response => {
          setReports([...reports, response.data]);
          setShowModal(false);
      })
      .catch(error => console.error('Error creating report:', error));
  };


  const handleDeleteReport = (reportId) => {
    // Eliminar el reporte de la base de datos
    axios.delete(`http://127.0.0.1:8000/api/reports/${reportId}/`)
      .then(() => {
        setReports(reports.filter(report => report.id !== reportId));
      })
      .catch(error => console.error('Error deleting report:', error));
  };

  const exportToExcel = (selectedTypes) => {
    const data = model.filter(item => selectedTypes.includes(item.medical_equipment_display));
    
    // Aquí puedes usar la lógica que ya tienes para generar el archivo Excel
    // Filtrando solo los tipos seleccionados
  };

  const handleViewDetails = (reportId) => {
    navigate(`/app/report-details/${reportId}`);
  };

  return (
    <div>
      <RouteLayout title='Reporte Final de Cotizaciones para Equipos Medicos' icon={<IconTools />} headerItem={<HeaderBtn onClick={() => setShowModal(true)} />}>

        {/* Mostrar los reportes creados */}
        <table className="equipment-table">
          <thead>
            <tr>
              <th>Nombre del Reporte</th>
              <th>Tipos de Equipos Médicos</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, index) => (
              <tr key={index}>
                <td className="type-column">{report.name}</td>
                <td className="type-column">{report.types.join(', ')}</td>
                <td className="type-column">
                  <div className="type-action-buttons">
                    <button className="view-button" 
                      onClick={() => 
                        handleViewDetails(report.id)}>
                      <IconEye />
                    </button>
                    <button className="edit-button" >
                      <IconPencil />
                    </button>
                    <button className="delete-button" onClick={() => handleDeleteReport(report.id)}>
                      <IconTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal para crear el reporte */}
        <CreateReportModal
          show={showModal}
          onHide={() => setShowModal(false)}
          availableTypes={availableTypes}
          onCreate={handleCreateReport}
        />
      </RouteLayout>
    </div>
  );
};

export default FinalReportU;
