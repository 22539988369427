import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from "./layouts/MainLayout";
import MedicalEquipmentList from './pages/MedicalEquipmentList';
import FinalReportU from './pages/FinalReportUpdated';
import FinalReport from './pages/FinalReport';
import Suppliers from './pages/Suppliers';
import Brands from './pages/Brands';
import 'bootstrap/dist/css/bootstrap.min.css';
import Models from './pages/Models';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/app/medical-equipment" replace />} />
          <Route path="/app" element={<MainLayout />}>
            <Route path="medical-equipment" element={<MedicalEquipmentList />} />
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="brands" element={<Brands />} />
            <Route path="models" element={<Models />} />
            <Route path="final-report" element={<FinalReportU />} />
            <Route path="/app/report-details/:reportId" element={<FinalReport />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;