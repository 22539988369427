import { useEffect, useState } from 'react';
import client from "../axiosConfig";

const useSupplier = () => {
    const [supplier, setSupplier] = useState([]);

    const reloadSupplier = async () => {
        try {
            const response = await client.get('/api/suppliers/');
            setSupplier(response.data);
            console.log('Datos cargados desde reload de Supplier:', response.data);
        } catch (error) {
            console.error('Error fetching Supplier:', error);
        }
        };


    const createSupplier = async (newSupplier) => {
        console.log('ESTOS SON LOS DATOS', newSupplier)
        try {
            const response = await client.post('/api/suppliers/', newSupplier);
            setSupplier((prevSupplier) => [...prevSupplier, response.data]);
        } catch (error) {
            console.error('Error creating Supplier:', error);
        }
        };

    const deleteSupplier = async (id) => {
        try {
            await client.delete(`/api/suppliers/${id}/`);
            setSupplier((prevSupplier) => prevSupplier.filter((item) => item.id !== id));
        } catch (error) {
            console.error('Failed to delete Supplier', error);
        }
        };

    const editSupplier = async (updatedSupplier) => {
        try {
            const response = await client.put(
                `/api/suppliers/${updatedSupplier.id}/`,
                updatedSupplier
            );
            setSupplier((prevSupplier) =>
                prevSupplier.map((item) => (item.id === updatedSupplier.id ? response.data : item))
            );
        } catch (error) {
            console.error('Error editing Supplier:', error);
        }
    };
    

    useEffect(() => {
        reloadSupplier();
    }, []);

    return {
        supplier,
        reloadSupplier,
        createSupplier,
        deleteSupplier,
        editSupplier
    };
};

export default useSupplier;
