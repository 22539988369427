import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CreateReportModal = ({ show, onHide, availableTypes, onCreate }) => {
  const [reportName, setReportName] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);

  const handleTypeSelection = (typeId) => {
    if (selectedTypes.includes(typeId)) {
      setSelectedTypes(selectedTypes.filter(id => id !== typeId));
    } else {
      setSelectedTypes([...selectedTypes, typeId]);
    }
  };

  const handleCreateReport = () => {
    // Validar que se haya ingresado un nombre y seleccionado al menos un tipo
    if (reportName.trim() === '' || selectedTypes.length === 0) {
      alert("Por favor, ingrese un nombre y seleccione al menos un tipo de equipo médico.");
      return;
    }

    // Llamar a la función onCreate con el nombre del reporte y los tipos seleccionados
    onCreate(reportName, selectedTypes);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Crear nuevo reporte</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="reportName">
            <Form.Label>Nombre del reporte</Form.Label>
            <Form.Control
              type="text"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
              placeholder="Nombre del reporte"
            />
          </Form.Group>
          <Form.Group controlId="typesSelection">
            <Form.Label>Seleccionar tipos de equipos médicos</Form.Label>
            {availableTypes.map((type) => (
              <Form.Check
                key={type.id}
                type="checkbox"
                label={`${type.parentName} - ${type.type}`}
                checked={selectedTypes.includes(type.id)}
                onChange={() => handleTypeSelection(type.id)}
              />
            ))}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleCreateReport}>
          Crear Reporte
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateReportModal;
