import { IconPencil, IconPlus, IconTrash, IconEye, IconBrandProducthunt, IconRotateClockwise } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import './styles.css';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import GeneralModal from '../components/modals/GeneralModal';
import useBrand from '../hooks/useBrand.js';
import RouteLayout from '../layouts/RouteLayout';


const HeaderBtn = ({ onBrandClic, onModelClic }) => (
    <div className='h-full flex flex-row items-center pr-4'>
        <button className='add-button' onClick={onBrandClic}><IconBrandProducthunt />Añadir Marca</button>
    </div>
)
    
function Brands() {

    const { brand, createBrand, deleteBrand, editBrand, reload } = useBrand();
    const [showModalDeleted, setShowModalDeleted] = useState(false);
    const [showModalCreatedBrand, setShowModalCreatedBrand] = useState(false);
    const [ShowModalUpdateBrand, setShowModalUpdateBrand] = useState(false);
    const [brandId, setBrandId] = useState('');
    const [brandName, setBrandName] = useState('');
    const [itemId, setItemId] = useState('');
    const [itemName, setItemName] = useState('');
    const [itemType, setItemType] = useState('');
    
    const openDeleteModal = (id, name, type) => {
        setItemId(id);
        setItemName(name);
        setShowModalDeleted(true);
        console.log(`Eliminando el elemento con id: ${itemId} y nombre: ${itemName}`);
    };

    const handleDelete = async () => {
        if (itemId !== null) {
            if (itemType === 'brand') {
                await deleteBrand(itemId);
                reload(); // Recargar los datos después de eliminar un equipo
            } else if (itemType === 'model') {

            }
        }
        setShowModalDeleted(false);
        setItemId('');
        setItemName('');
        reload();
    };

    const handleCreateBrand = async () => {
        await createBrand({
            name: brandName,
        });
        setShowModalCreatedBrand(false);
        setBrandName('');
        reload();
    };

    const handleUpdateBrand = async () => {
        await editBrand({
            id: brandId,
            name: brandName,
        });
        setShowModalUpdateBrand(false);
        setBrandId('');
        setBrandName('');
        reload();
    };


    return (
    <div>
        <RouteLayout title='Lista de Marcas y Modelos' icon={<IconBrandProducthunt />} headerItem={<HeaderBtn onBrandClic={() => setShowModalCreatedBrand(true)} />}>
        <table className="equipment-table">
            <thead>
                <tr>
                    <th>Marca</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {brand.map(item => (
                        <tr key={item.id}>
                            <td className="type-column">
                                <span className="type-text">{item.name}</span>
                            </td>
                            <td className="type-column">
                                <span className="type-text">
                                    <div className="type-action-buttons">
                                            <button onClick={() => {
                                                setShowModalUpdateBrand(true);
                                                setBrandName(item.name);
                                                }} className="edit-button" >
                                                <IconPencil />
                                            </button>
                                            <button className="delete-button" onClick={() => openDeleteModal(item.id, item.name, 'model')}>
                                                <IconTrash />
                                            </button>
                                        </div>
                                </span>
                            </td>
                        </tr>
                ))}
            </tbody>
        </table>
        </RouteLayout>
        <GeneralModal
            isOpen={showModalCreatedBrand}
            onClose={() => setShowModalCreatedBrand(false)}
            title="Crear Marca"
            footerActions={
                <button
                    type="button"
                    onClick={handleCreateBrand}
                >
                Crear
                </button>}
        >
                <div>
                    <label>Nombre de la Marca</label>
                    <input 
                        type="text" 
                        value={brandName} 
                        onChange={(e) => setBrandName(e.currentTarget.value)}
                    />
                </div>
        </GeneralModal>
        <GeneralModal
            isOpen={ShowModalUpdateBrand}
            onClose={() => setShowModalUpdateBrand(false)}
            title="Editar Marca"
            footerActions={
                <button
                    type="button"
                    onClick={handleUpdateBrand}
                >
                Actualizar
                </button>}
        >
            <div>
                <label>Nombre de la Marca</label>
                <input
                    type="text"
                    value={brandName}
                    onChange={(e) => setBrandName(e.currentTarget.value)}
                />
            </div>
        </GeneralModal>
        <ConfirmationModal
            show={showModalDeleted}
            onClose={() => setShowModalDeleted(false)}
            onConfirm={handleDelete}
            title="Confirmar Eliminación"
            body={`¿Está seguro que desea eliminar el elemento "${itemName}"?`}
        />
    </div>
    );
}

export default Brands;