import { useEffect, useState } from 'react';
import client from "../axiosConfig";

const useSpecification = () => {
    const [specification, setSpecification] = useState([]);

    const reloadSpecification = async () => {
        try {
            const response = await client.get('/api/technical_specifications/');
            setSpecification(response.data);
            console.log('Datos cargados desde reload de Specification:', response.data);
        } catch (error) {
            console.error('Error fetching specification:', error);
        }
        };


    const createSpecification = async (newSpecification) => {
        console.log('ESTOS SON LOS DATOS DE LA ESPECIFICACION', newSpecification)
        try {
            const response = await client.post('/api/technical_specifications/', newSpecification);
            setSpecification((prevSpecification) => [...prevSpecification, response.data]);
        } catch (error) {
            console.error('Error creating specification:', error);
        }
        };

    const deleteSpecification = async (id) => {
        try {
            await client.delete(`/api/technical_specifications/${id}/`);
            setSpecification((prevSpecification) => prevSpecification.filter((item) => item.id !== id));
        } catch (error) {
            console.error('Failed to delete specification', error);
        }
        };

    const editSpecification = async (updatedSpecification) => {
        try {
            const response = await client.put(
                `/api/technical_specifications/${updatedSpecification.id}/`,
                updatedSpecification
            );
            setSpecification((prevSpecification) =>
                prevSpecification.map((item) => (item.id === updatedSpecification.id ? response.data : item))
            );
        } catch (error) {
            console.error('Error editing specification:', error);
        }
    };
    

    useEffect(() => {
        reloadSpecification();
    }, []);

    return {
        specification,
        reloadSpecification,
        createSpecification,
        deleteSpecification,
        editSpecification
    };
};

export default useSpecification;
