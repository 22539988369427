import { useEffect, useState } from 'react';
import client from "../axiosConfig";

const useBrand = () => {
    const [brand, setBrand] = useState([]);

    const reload = async () => {
        try {
            const response = await client.get('/api/brands/')
            setBrand(response.data);
            console.log('Datos cargados desde reload de Brand:', response.data);
        } catch (error) {
            console.error('Error al recargar los equipos médicos:', error);
        }
    };

    const createBrand = async (newBrand) => {
        console.log('ESTOS SON LOS DATOS desde create brand', newBrand)
        try {
            const response = await client.post('/api/brands/', newBrand);
            setBrand((prevBrand) => [...prevBrand, response.data]);
        } catch (error) {
            console.error('Error creating Brand:', error);
        }
        };

    const deleteBrand = async (id) => {
        try {
            await client.delete(`/api/brands/${id}/`);
            setBrand((prevBrand) => prevBrand.filter((item) => item.id !== id));
        } catch (error) {
            console.error('Failed to delete Brand', error);
        }
        };

    const editBrand = async (updatedBrand) => {
        try {
            const response = await client.put(
                `/api/brands/${updatedBrand.id}/`,
                updatedBrand
            );
            setBrand((prevBrand) =>
                prevBrand.map((item) => (item.id === updatedBrand.id ? response.data : item))
            );
        } catch (error) {
            console.error('Error editing Brand:', error);
        }
    };
    

    useEffect(() => {
        reload();
    }, []);

    return {
        brand,
        reload,
        createBrand,
        deleteBrand,
        editBrand
    };
};

export default useBrand;