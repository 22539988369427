import { IconPencil, IconPlus, IconTrash, IconBuildingStore, IconRotateClockwise } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import './styles.css';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import GeneralModal from '../components/modals/GeneralModal';
import useSupplier from '../hooks/useSupplier';

import RouteLayout from '../layouts/RouteLayout';


const HeaderBtn = ({ onClick }) => (
    <div className='h-full flex flex-row items-center pr-4'>
        <button className='add-button' onClick={onClick}><IconBuildingStore />Añadir Nuevo Proveedor</button>
    </div>
)

function Supplier() {

    const { supplier, createSupplier, deleteSupplier, editSupplier, reloadSupplier } = useSupplier();
    const [showModalDeleted, setShowModalDeleted] = useState(false);
    const [showModalCreatedSupplier, setShowModalCreatedSupplier] = useState(false);
    const [ShowModalUpdateSupplier, setShowModalUpdateSupplier] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");

    const openDeleteModal = (id, name) => {
        setId(id);
        setName(name);
        setShowModalDeleted(true);
        console.log(`Eliminando el elemento con id: ${id} y nombre: ${name}`);
    };

    const handleDelete = async () => {
        if (id !== null) {
            await deleteSupplier(id);
        };
        setShowModalDeleted(false);
        setId("");
        setName("");
        reloadSupplier();
    };

    const handleCreateSupplier = async () => {
        console.log("Creating Supplier:", name);
        await createSupplier({
            name: name,
            email: email,
            address: address,
            phone: phone,
        });
        setShowModalCreatedSupplier(false);
        setName("");
        setEmail("");
        setAddress("");
        setPhone("");
        reloadSupplier();
    };

    const handleUpdateSupplier = async () => {
        console.log("Update equipment:", name);
        await editSupplier({
            id: id,
            name: name,
            email: email,
            address: address,
            phone: phone,
        });
        setShowModalUpdateSupplier(false);
        setId("");
        setName("");
        setEmail("");
        setAddress("");
        setPhone("");
        reloadSupplier();
    };

    return (
    <div>
        <RouteLayout title='Lista de Proveedores' icon={<IconBuildingStore />} headerItem={<HeaderBtn onClick={() => setShowModalCreatedSupplier(true)} />}>
            <table className="equipment-table">
                <thead>
                <tr>
                    <th>Nombre Proveedor</th>
                    <th>Correo Electrónico</th>
                    <th>Dirección</th>
                    <th>Telefono</th>
                    <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                    {supplier.map(item => (
                        <tr key={item.id}>
                            <td className="type-column">
                                <span className="type-text">{item.name}</span>
                            </td>
                            <td className="type-column">
                                {item.email && item.email.length > 0 ? (
                                        <span className="type-text">{item.email}</span>
                                ) : (
                                        <span className="type-text">Sin Información</span>
                                )}
                            </td>
                            <td className="type-column">
                                {item.address && item.address.length > 0 ? (
                                        <span className="type-text">{item.address}</span>
                                ) : (
                                        <span className="type-text">Sin Información</span>
                                )}
                            </td>
                            <td className="type-column">
                                {item.phone && item.phone.length > 0 ? (
                                        <span className="type-text">{item.phone}</span>
                                ) : (
                                        <span className="type-text">Sin Información</span>
                                )}
                            </td>
                            <td className="type-column">
                                    <div className="type-action-buttons">
                                        <button onClick={() => {
                                            setShowModalUpdateSupplier(true); 
                                            setId(item.id); 
                                            setName(item.name); 
                                            setEmail(item.email); 
                                            setAddress(item.address);
                                            setPhone(item.phone)
                                            }} className="edit-button" >
                                            <IconPencil />
                                        </button>
                                        <button className="delete-button" onClick={() => openDeleteModal(item.id, item.name)}>
                                            <IconTrash />
                                        </button>
                                    </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </RouteLayout>
        <GeneralModal
            isOpen={showModalCreatedSupplier}
            onClose={() => setShowModalCreatedSupplier(false)}
            title="Crear Proveedor"
            footerActions={
                <button
                    type="button"
                    onClick={handleCreateSupplier}
                >
                Crear
                </button>}
        >
                <div>
                    <label>Nombre del Proveedor</label>
                    <input 
                        type="text" 
                        value={name} 
                        onChange={(e) => setName(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <label>Correo Electrónico</label>
                    <input 
                        type="text" 
                        value={email} 
                        onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <label>Dirección</label>
                    <input 
                        type="text" 
                        value={address} 
                        onChange={(e) => setAddress(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <label>Telefono</label>
                    <input 
                        type="text" 
                        value={phone} 
                        onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                </div>
        </GeneralModal>
        <GeneralModal
            isOpen={ShowModalUpdateSupplier}
            onClose={() => setShowModalUpdateSupplier(false)}
            title="Editar Proveedor"
            footerActions={
                <button
                    type="button"
                    onClick={handleUpdateSupplier}
                >
                Actualizar
                </button>}
        >
                <div>
                    <label>Nombre del Proveedor</label>
                    <input 
                        type="text" 
                        value={name} 
                        onChange={(e) => setName(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <label>Correo Electrónico</label>
                    <input 
                        type="text" 
                        value={email} 
                        onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <label>Dirección</label>
                    <input 
                        type="text" 
                        value={address} 
                        onChange={(e) => setAddress(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <label>Telefono</label>
                    <input 
                        type="text" 
                        value={phone} 
                        onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                </div>
        </GeneralModal>
        <ConfirmationModal
            show={showModalDeleted}
            onClose={() => setShowModalDeleted(false)}
            onConfirm={handleDelete}
            title="Confirmar Eliminación"
            body={`¿Está seguro que desea eliminar el elemento "${name}"?`}
        />
    </div>
    );
}

export default Supplier;