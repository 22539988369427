import { IconPencil, IconPlus, IconTrash, IconEye, IconBrandProducthunt, IconRotateClockwise } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import './styles.css';
import ConfirmationModal from '../components/modals/ConfirmationModal.js';
import GeneralModal from '../components/modals/GeneralModal.js';
import useMedicalEquipment from '../hooks/useMedicalEquipment.js';
import useBrand from '../hooks/useBrand.js';
import useModel from '../hooks/useModel.js';
import useSupplier from '../hooks/useSupplier.js';
import RouteLayout from '../layouts/RouteLayout.js';


const HeaderBtn = ({ onBrandClic, onModelClic }) => (
    <div className='h-full flex flex-row items-center pr-4'>
        <button className='add-button' onClick={onModelClic}><IconPlus />Añadir Modelo</button>
    </div>
)

const ViewDetailsModalContent = ({ item }) => {
    return (
        <div>
            <h5>Equipo Médico:</h5><p>{item.medical_equipment_display}</p>
            <h5>Proveedor:</h5><p>{item.suppliers_name}</p>
            <h5>Marca:</h5><p>{item.brand_name}</p>
            <h5>Modelo:</h5><p>{item.name}</p>
            <h5>Especificaciones Técnicas:</h5>
            <table className="equipment-table">
                <thead> 
                    <tr>
                        <th>Especificación</th>
                        <th>Especificación</th>
                        <th>Página del Manual</th>
                    </tr>
                </thead>
                <tbody>
                    {item.model_technical_specifications
                        // .sort((a, b) => a.order - b.order)
                        .map((techSpec, index) => {
                        return (
                        <tr key={index}>
                            <td>{techSpec.technical_specification_name}</td>
                            <td>{techSpec.technical_compliance}</td> 
                            <td>{techSpec.manual_reference_page}</td> 
                        </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
    };

const EditDetailsModalContent = ({ item, formValues, setFormValues }) => {
    console.log("Este es el item seleccionado: ", item)
    
    const handleInputChange = (specId, field, value) => {
        setFormValues(prevValues =>
            prevValues.map(item =>
                item.specId === specId ? { ...item, [field]: value } : item
            )
        );
    };
    
    return (
        <div>
            <h5>Especificaciones Técnicas:</h5>
            <table className="equipment-table">
                <thead> 
                    <tr>
                        <th>Especificación</th>
                        <th>Especificación</th>
                        <th>Página del Manual</th>
                    </tr>
                </thead>
                <tbody>
                    {item.equipment_technical_specifications
                        .sort((a, b) => a.order - b.order)
                        .map((techSpec, index) => {
                        return (
                        <tr key={index}>
                            <td>{techSpec.specification}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={
                                            formValues.find(
                                                (f) =>
                                                    f.specId ===
                                                    techSpec.id
                                            )?.technical_compliance || ""
                                        }
                                        onChange={(e) =>
                                            handleInputChange(
                                                techSpec.id,
                                                "technical_compliance",
                                                e.target.value
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={
                                            formValues.find(
                                                (f) =>
                                                    f.specId ===
                                                    techSpec.id
                                            )?.manual_reference_page || ""
                                        }
                                        onChange={(e) =>
                                            handleInputChange(
                                                techSpec.id,
                                                "manual_reference_page",
                                                e.target.value
                                            )
                                        }
                                    />
                                </td>
                        </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
    };
    
function Models() {

    const { model, createModel, deleteModel, editModel, reloadModel } = useModel();
    const { brand } = useBrand();
    const { equipment } = useMedicalEquipment();
    const { supplier } = useSupplier();
    const [showModalDeleted, setShowModalDeleted] = useState(false);
    const [showModalCreatedModel, setShowModalCreatedModel] = useState(false);
    const [ShowModalUpdateModel, setShowModalUpdateModel] = useState(false);
    const [showModalViewDetails, setShowModalViewDetails] = React.useState(false);
    const [brandId, setBrandId] = useState('');
    const [brandName, setBrandName] = useState('');
    const [modelId, setModelId] = useState('');
    const [modelName, setModelName] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const [itemId, setItemId] = useState('');
    const [itemName, setItemName] = useState('');
    const [selectedMedicalEquipment, setSelectedMedicalEquipment] = useState('');
    const [selectedEquipmentId, setSelectedEquipmentId] = useState('');
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [formValues, setFormValues] = useState([]);

    useEffect(() => {
        if (selectedItem) {
            const initialFormValues = selectedItem.equipment_technical_specifications.map(spec => ({
                specId: spec.id,
                technical_compliance: spec.technical_compliance || '',
                manual_reference_page: spec.manual_reference_page || ''
            }));
            setFormValues(initialFormValues);
        }
    }, [selectedItem, setFormValues]);
    
    const openDeleteModal = (id, name) => {
        setItemId(id);
        setItemName(name);
        setShowModalDeleted(true);
        console.log(`Eliminando el elemento con id: ${itemId} y nombre: ${itemName}`);
    };

    const handleDelete = async () => {
        if (itemId !== null) {

                await deleteModel(itemId);
                reloadModel(); // Recargar los datos después de eliminar una especificación
        }
        setShowModalDeleted(false);
        setItemId('');
        setItemName('');
        reloadModel();
    };

    const handleCreateModel = async () => {
        await createModel({
            name: modelName,
            brand: brandId,
            suppliers: supplierId,
            medical_equipment: selectedMedicalEquipment,
        });
        setShowModalCreatedModel(false);
        setModelId('');
        setModelName('');
        setBrandId('');
        setSupplierId('');
        setBrandName('');
        setSelectedMedicalEquipment('');
        reloadModel();
    };

    const handleUpdateModel = async () => {
        await editModel({
            id: modelId,
            name: modelName,
            brand: brandId,
            suppliers: supplierId,
            medical_equipment: selectedEquipmentId,
            specifications: formValues,
        });
        console.log("Datos del equipo medico: ", equipment)
        console.log("Update Model: name - ", modelName, "Brand - ", brandId, "Equipo Medico - ", selectedMedicalEquipment);
        setShowModalUpdateModel(false);
        setModelId('');
        setModelName('');
        setBrandId('');
        setBrandName('');
        setSelectedMedicalEquipment('');
        reloadModel();
    };


    return (
    <div>
        <RouteLayout title='Lista de Marcas y Modelos' icon={<IconBrandProducthunt />} headerItem={<HeaderBtn onModelClic={() => setShowModalCreatedModel(true)}  />}>
        <table className="equipment-table">
            <thead>
                <tr>
                    <th>Marca</th>
                    <th>Proveedores</th>
                    <th>Equipos Medicos</th>
                    <th>Modelo</th>
                </tr>
            </thead>
            <tbody>
                {model.map(item => (
                        <tr key={item.id}>
                            <td className="type-column">
                                <span className="type-text">{item.brand_name}</span>
                            </td>
                            <td className="type-column">
                                <span className="type-text">{item.suppliers_name}</span>
                            </td>
                            <td className="type-column">
                                <span className="type-text">{item.medical_equipment_display}</span>
                            </td>
                            <td className="type-column">
                                <span className="type-text">{item.name}
                                    <div className="type-action-buttons">
                                            <button className="view-button" onClick={() => { setShowModalViewDetails(true); setSelectedItem(item); }}>
                                            <IconEye />
                                            </button>
                                            <button onClick={() => {
                                                setShowModalUpdateModel(true);
                                                setModelId(item.id);
                                                setModelName(item.name);
                                                setBrandName(item.brand_name);
                                                setBrandId(item.brand);
                                                setSelectedMedicalEquipment(item.medical_equipment_display);
                                                setSelectedEquipmentId(item.medical_equipment_id)
                                                setSupplierName(item.suppliers_name);
                                                setSupplierId(item.suppliers)
                                                setSelectedItem(item);
                                                }} className="edit-button" >
                                                <IconPencil />
                                            </button>
                                            <button className="delete-button" onClick={() => openDeleteModal(item.id, item.name)}>
                                                <IconTrash />
                                            </button>
                                        </div>
                                </span>
                            </td>
                        </tr>
                ))}
            </tbody>
        </table>


        </RouteLayout>
        <GeneralModal
            isOpen={showModalCreatedModel}
            onClose={() => setShowModalCreatedModel(false)}
            title="Crear Modelo"
            footerActions={
                <button
                    type="button"
                    onClick={handleCreateModel}
                >
                Crear
                </button>}
        >
            <div>
                <label>Nombre de la Marca</label>
                <select
                    value={brandId}
                    onChange={(e) => {
                        setBrandId(e.target.value);
                    }}
                >
                    <option value="">Seleccione una opción</option>
                    {brand
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(b => (
                        <option key={b.id} value={b.id}>
                            {b.name}
                        </option>
                    ))}
                </select>

            </div>
            <div>
                <label>Equipo Médico:</label>
                <select
                    value={selectedMedicalEquipment}
                    onChange={(e) => {
                        setSelectedMedicalEquipment(e.target.value);
                    }}
                >
                    <option value="">Seleccione una opción</option>
                    {equipment
                    .filter(eq => eq.parent !== null)  // Filtra solo equipos hijos
                    .map(eq => {
                        const parent = equipment.find(parentEq => parentEq.id === eq.parent);
                        const parentName = parent ? parent.type : '';
                        return {
                            id: eq.id,
                            type: eq.type,
                            parentName: parentName
                        };
                    })
                    .sort((a, b) => {
                        if (a.parentName < b.parentName) return -1;
                        if (a.parentName > b.parentName) return 1;
                        if (a.type < b.type) return -1;
                        if (a.type > b.type) return 1;
                        return 0;
                    })
                    .map(eq => (
                        <option key={eq.id} value={eq.id}>
                            {eq.parentName} - {eq.type}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Proveedor:</label>
                <select
                    value={supplierId}
                    onChange={(e) => {
                        setSupplierId(e.target.value);
                    }}
                >
                    <option value="">Seleccione una opción</option>
                    {supplier
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(s => (
                        <option key={s.id} value={s.id}>
                            {s.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Nombre del Modelo</label>
                <input
                    type="text"
                    value={modelName}
                    onChange={(e) => setModelName(e.currentTarget.value)}
                />
            </div>
        </GeneralModal>
        <GeneralModal
            isOpen={ShowModalUpdateModel}
            onClose={() => setShowModalUpdateModel(false)}
            title="Editar Modelo"
            footerActions={
                <button
                    type="button"
                    onClick={handleUpdateModel}
                >
                Actualizar
                </button>}
        >
            <div>
                <label>Marca:</label>
                <select
                    value={brandId}
                    onChange={(e) => {
                        setBrandId(e.target.value);
                    }}
                >
                    <option value="">{brandName}</option>
                    {brand
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(b => (
                        <option key={b.id} value={b.id}>
                            {b.name}
                        </option>
                    ))}
                </select>
            </div>
                <div>
                <label>Equipo Médico:</label>
                <select
                    value={selectedEquipmentId}
                    onChange={(e) => {
                        setSelectedEquipmentId(e.target.value);
                    }}
                >
                    <option value="">{selectedMedicalEquipment}</option>
                    {equipment
                    .filter(eq => eq.parent !== null)  // Filtra solo equipos hijos
                    .map(eq => {
                        const parent = equipment.find(parentEq => parentEq.id === eq.parent);
                        const parentName = parent ? parent.type : '';
                        return {
                            id: eq.id,
                            type: eq.type,
                            parentName: parentName
                        };
                    })
                    .sort((a, b) => {
                        if (a.parentName < b.parentName) return -1;
                        if (a.parentName > b.parentName) return 1;
                        if (a.type < b.type) return -1;
                        if (a.type > b.type) return 1;
                        return 0;
                    })
                    .map(eq => (
                        <option key={eq.id} value={eq.id}>
                            {eq.parentName} - {eq.type}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Proveedor:</label>
                <select
                    value={supplierId}
                    onChange={(e) => {
                        setSupplierId(e.target.value);
                    }}
                >
                    <option value="">{supplierName}</option>
                    {supplier
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(s => (
                        <option key={s.id} value={s.id}>
                            {s.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Modelo</label>
                <input
                    type="text"
                    value={modelName}
                    onChange={(e) => setModelName(e.currentTarget.value)}
                />
            </div>
            {selectedItem && <EditDetailsModalContent item={selectedItem} formValues={formValues} setFormValues={setFormValues} />}

        </GeneralModal>
        <GeneralModal
            isOpen={showModalViewDetails}
            onClose={() => setShowModalViewDetails(false)}
            title="Detalles de la Cotización"
            footerActions={<button onClick={() => setShowModalViewDetails(false)}>Cerrar</button>}
        >
            {selectedItem && <ViewDetailsModalContent item={selectedItem} />}
        </GeneralModal>
        <ConfirmationModal
            show={showModalDeleted}
            onClose={() => setShowModalDeleted(false)}
            onConfirm={handleDelete}
            title="Confirmar Eliminación"
            body={`¿Está seguro que desea eliminar el elemento "${itemName}"?`}
        />
    </div>
    );
}

export default Models;